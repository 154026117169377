import { render, staticRenderFns } from "./VendorProfileDialog.vue?vue&type=template&id=69682bb2&scoped=true&lang=pug&"
import script from "./VendorProfileDialog.vue?vue&type=script&lang=ts&"
export * from "./VendorProfileDialog.vue?vue&type=script&lang=ts&"
import style0 from "./VendorProfileDialog.vue?vue&type=style&index=0&id=69682bb2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69682bb2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAvatar,VBtn,VCard,VCardText,VDialog,VDivider,VFlex,VIcon,VLayout,VRating,VSpacer,VTab,VTabItem,VTabs,VToolbar,VToolbarTitle})
