import tierDefault from "./tierDefault";
import categoryDefault from "./categoryDefault";
import uploadDefault from "./uploadDefault";

export default {
  instanceDefaults(data, { store, Model, Models }) {
    return {
      service: true,
      name: "",
      displayName: "",
      displayNameSubtitle: "",
      price: 0,
      addonPrice: 0,
      description: "",
      serviceTypes: [],
      images: [],
      vendorCut: 0,
      category: null,
      tier: null,
      displayOrder: 99,
      categoryModel: categoryDefault,
      serviceTypeModel: categoryDefault,
      tierModel: tierDefault,
      imageModels: []
    };
  }
};
