import vendorDefault from "./vendorDefault";
import bookingDefault from "./bookingDefault";
import { CLAIM_STATUS } from "../common";

export default {
  instanceDefaults(data, { store, Model, Models }) {
    return {
      vendor: null,
      booking: null,
      status: CLAIM_STATUS.UNKNOWN,
      isMax: false,
      distance: 0,
      vendorModel: vendorDefault,
      bookingModel: bookingDefault
    };
  }
};
