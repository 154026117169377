export default {
  instanceDefaults(data, { store, Model, Models }) {
    return {
      code: "",
      enabled: false,
      description: "",
      type: undefined,
      restriction: undefined,
      services: [],
      percentage:0,
    };
  }
};
