import { render, staticRenderFns } from "./BookingChooseCategory.vue?vue&type=template&id=afa50d50&scoped=true&lang=pug&"
import script from "./BookingChooseCategory.vue?vue&type=script&lang=ts&"
export * from "./BookingChooseCategory.vue?vue&type=script&lang=ts&"
import style0 from "./BookingChooseCategory.vue?vue&type=style&index=0&id=afa50d50&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afa50d50",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VLayout,VSpacer})
