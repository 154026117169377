import { render, staticRenderFns } from "./HeaderAndCrumbs.vue?vue&type=template&id=3c584f62&scoped=true&lang=pug&"
import script from "./HeaderAndCrumbs.vue?vue&type=script&lang=ts&"
export * from "./HeaderAndCrumbs.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderAndCrumbs.vue?vue&type=style&index=0&id=3c584f62&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c584f62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VIcon,VLayout,VSpacer})
