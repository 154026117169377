import { POST_STATUS } from "../common";

export default {
  status: POST_STATUS.DRAFT,
  author: null,
  authorModel: null,
  authorName: "",
  title: "",
  image: null,
  imageModel: null,
  permalink: "",
  body: ""
};
