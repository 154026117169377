export default {
  instanceDefaults(data, { store, Model, Models }) {
    return {
      email: "",
      password: "",
      phone: null,
      needsNewToken: false,
      submittedToken: null,
      user: null
    };
  }
};
