import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/src/stylus/app.styl";

const bastille = "#2c2e32";
const coralPink = "#f88379";
const sweetPink = "#f5a09c";
const offWhite = "#f5f5f6";
const white = "#ffffff";
Vue.use(Vuetify, {
  iconfont: "md",
  theme: {
    primary: coralPink,
    secondary: bastille,
    accent: sweetPink,
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00"
  }
});
