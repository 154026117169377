import userDefault from "./userDefault";
import tierDefault from "./tierDefault";
import categoryDefault from "./categoryDefault";

export default {
  instanceDefaults(data, { store, Model, Models }) {
    return {
      firstName: "",
      lastName: "",
      disabled: false,
      sortPriority: 25,
      desiredTravelRadius: 25,
      maximumTravelRadius: 25,
      latitude: 0,
      longitude: 0,
      address: null,
      aboutMe: null,
      reviews: [],
      serviceTypes: [],
      user: null,
      tier: null,
      profileImage: null,
      profileImageModel: null,
      workImageModels: [],
      workImages: [],
      reviewModels: [],
      serviceTypeModels: [],
      get fullName() {
        return `${this.firstName} ${this.lastName}`;
      },
      userModel: userDefault,
      tierModel: tierDefault,
      submittedToken: null,
      requestedCode: null,
      needsNewToken: false
    };
  }
};
