import vendorDefault from "./vendorDefault";

export default {
  email: "",
  roles: [],
  vendorModel: null,
  purchaserModel: null,
  requestedCode: null,
  submittedToken: null,
  needsNewToken: false
};
