import { render, staticRenderFns } from "./BookingNavbar.vue?vue&type=template&id=855a8a48&scoped=true&lang=pug&"
import script from "./BookingNavbar.vue?vue&type=script&lang=ts&"
export * from "./BookingNavbar.vue?vue&type=script&lang=ts&"
import style0 from "./BookingNavbar.vue?vue&type=style&index=0&id=855a8a48&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855a8a48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VSpacer,VToolbar})
