import { make } from "vuex-pathify";

const state = {
  appBarVisible: false,
  toolbarMode: 0,
  redirectPath: null
};
const mutations = make.mutations(state);
const actions = make.actions(state);
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters: {}
};
