import { render, staticRenderFns } from "./BookingDateDialog.vue?vue&type=template&id=2a53d003&scoped=true&lang=pug&"
import script from "./BookingDateDialog.vue?vue&type=script&lang=ts&"
export * from "./BookingDateDialog.vue?vue&type=script&lang=ts&"
import style0 from "./BookingDateDialog.vue?vue&type=style&index=0&id=2a53d003&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a53d003",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VDivider,VLayout,VList,VSubheader,VSwitch})
