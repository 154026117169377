import Vue from "vue";
import Vuex from "vuex";
import feathersVuex from "feathers-vuex";
import feathersClient from "@/plugins/feathers-client";
import pathify from "vuex-pathify";
import VuexPersist from "vuex-persist";
import booking from "@/store/booking";
import ui from "@/store/ui";
import packages from "@/store/packages";
import userDefault from "../instance-defaults/userDefault";
import categoryDefault from "../instance-defaults/categoryDefault";
import tierDefault from "../instance-defaults/tierDefault";
import vendorDefault from "../instance-defaults/vendorDefault";
import bookingDefault from "../instance-defaults/bookingDefault";
import purchasableDefault from "../instance-defaults/purchasableDefault";
import purchaserDefault from "../instance-defaults/purchaserDefault";
import vendorClaimDefault from "../instance-defaults/vendorClaimDefault";
import couponDefault from "../instance-defaults/couponDefault";
import orderDefault from "../instance-defaults/orderDefault";
import reviewDefault from "../instance-defaults/reviewDefault";
import "viewerjs/dist/viewer.min.css";
import VueGtm from "@gtm-support/vue2-gtm";
import VueGtmConfig from "../plugins/vue-gtm";
import Viewer from 'v-viewer';
import postDefault from "../instance-defaults/postDefault";

const { service, auth, FeathersVuex } = feathersVuex(feathersClient, {
  idField: "_id"
});

Vue.use(Vuex);
Vue.use(FeathersVuex);
Vue.use(Viewer);
Vue.use(VueGtm, VueGtmConfig);
const state = {};
const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage,
  reducer: stateToReduce => ({
    booking: stateToReduce.booking,
    packages: stateToReduce.packages
  })
});

export default new Vuex.Store({
  state,
  plugins: [
    vuexLocalStorage.plugin,
    pathify.plugin,
    service("coupons", couponDefault),
    service("categories", categoryDefault),
    service("service-types", categoryDefault),
    service("vendors", vendorDefault),
    service("users", userDefault),
    service("tiers", tierDefault),
    service("orders", orderDefault),
    service("posts", postDefault),
    service("vendor-claims", vendorClaimDefault),
    service("keyed-images"),
    service("page-configs"),
    service("bookings", bookingDefault),
    service("settings"),
    service("uploads"),
    service("downloads"),
    service("purchasables", purchasableDefault),
    service("purchasers", purchaserDefault),
    service("reviews", reviewDefault),
    service("vendor-apps"),
    // Specify custom options per service
    /*service('/v1/tasks', {
          idField: '_id', // The field in each record that will contain the id
          nameStyle: 'path', // Use the full service path as the Vuex module name, instead of just the last section
          namespace: 'custom-namespace', // Customize the Vuex module name.  Overrides nameStyle.
          autoRemove: true, // Automatically remove records missing from responses (only use with feathers-rest)
          enableEvents: false, // Turn off socket event listeners. It's true by default
          addOnUpsert: true, // Add new records pushed by 'updated/patched' socketio events into store, instead of discarding them. It's false by default
          skipRequestIfExists: true, // For get action, if the record already exists in store, skip the remote request. It's false by default
          modelName: 'OldTask', // Default modelName would have been 'Task'
        }),

        // Add custom state, getters, mutations, or actions, if needed.  See example in another section, below.
        service('things', {
          state: {},
          getters: {},
          mutations: {},
          actions: {},
        }),

        // Setup a service with defaults for Model instances
        service('manufacturers', {
          instanceDefaults: {
            name: '',
          },
        }),
        // Setup a service with light-weight relational data
        service('models', {
          instanceDefaults: {
            name: '',
            manufacturerId: '',
            manufacturer: 'Manufacturer', // Refers to data (populated on the server) that gets put in the `manufacturers` vuex store.
          },
        }),*/

    // Setup the auth plugin.
    auth({ userService: "users" })
  ],
  modules: {
    booking,
    ui,
    packages
  }
});
