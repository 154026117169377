import { ORDER_STATUS } from "../common";

export default {
  status: ORDER_STATUS.PLACING,
  preCancellationStatus: null,
  products: [],
  bookings: [],
  user: null,
  servicesTotal: 0,
  proPaymentTotal: 0,
  discountTotal: 0,
  onCancelNotifyPurchaser: true,
  includeCancelReasonOnCustomerNotification: false,
  customerEmailed: false,
  internalCancelReason: "",
  customerCancelReason: "",
  customerCancelDetails: "",
  refundedGiftCardPayment: 0,
  stripeSessionId: null,
  stripePaymentObject: null,
  stripeSessionObject: null,
  bookingModels: [],
  discountEachLineItem: null,
  purchaserModel: null
};
