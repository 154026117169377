import feathers from "@feathersjs/feathers";
import auth from "@feathersjs/authentication-client";
import feathersRest from "@feathersjs/rest-client";
import axios from "axios";
import { getBaseURL, PRODUCTION_SERVER_URL } from "../common";

const feathersClient = feathers()
  .configure(feathersRest(getBaseURL()).axios(axios))
  .configure(auth({ storage: window.localStorage }));
export default feathersClient;
