import { IMAGE_POSITION } from "../common";
export default {
  instanceDefaults(data, { store, Model, Models }) {
    return {
      key: "",
      image: null,
      imageModel: uploadDefault,
      imagePosition: null
    };
  }
};
