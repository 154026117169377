import vendorDefault from "./vendorDefault";
import purchaserDefault from "./purchaserDefault";
import purchasableDefault from "./purchasableDefault";
import { BOOKING_STATUS } from "../common";

export default {
  instanceDefaults(data, { store, Model, Models }) {
    return {
      requestedVendor: null,
      assignedVendor: null,
      assignedVendors: [],
      proPaymentTotal: 0,
      proTipTotal: 0,
      groupPayment: 0,
      groupTip: 0,
      groupKey: null,
      groupProCount: 1,
      service: null,
      date: undefined,
      localizedDate: undefined,
      hour: 0,
      duration: 1,
      guests: 1,
      timezone: "",
      address: null,
      location: null,
      review: null,
      onCancelNotifyVendor: true,
      onChangeNotifyVendor: false,
      onChangeNotifyPurchaser: false,
      releasedFromRequestedVendor: false,
      status: BOOKING_STATUS.WAITING_ON_ANY_PRO,
      checkInTime: null,
      checkOutTime: null,
      resendEmails: null
      // purchaserModel: purchaserDefault,
      // requestedVendorModel: vendorDefault,
      // assignedVendorModel: vendorDefault,
      // purchasableModel: purchasableDefault
    };
  }
};
