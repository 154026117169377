import { render, staticRenderFns } from "./BookingCalendarBadge.vue?vue&type=template&id=40ffb35d&lang=pug&"
import script from "./BookingCalendarBadge.vue?vue&type=script&lang=ts&"
export * from "./BookingCalendarBadge.vue?vue&type=script&lang=ts&"
import style0 from "./BookingCalendarBadge.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VIcon,VLayout,VSheet,VSpacer})
